<template>
  <div></div>
</template>
<script>
import Vue from "vue";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

export default {
  data() {
    return {
      mutations: {
        BILL_CREATED: "CREATEBILL",
        BILL_PRINTED: "PRINTBILL",
        ADD_NEW_RECEP: "ADDRECEP",
        ADD_NEW_SENDER: "ADDSENDER",
        ADDED_LOGO_FROM_FILE: "CHANGELOGO",
        ADDED_LOGO_FROM_SITE: "CHANGELOGOSITE",
        ADD_NEW_CHAT_MESSAGES: "SUPPORT",
        EMAIL_DONE: "EMAILCONFIRM",
        SINGLEPRINT_ENVELOPE: "PRINTENV",
        SINGLEPRINT_INVOICE: "PRINTINVOICE",
        SINGLEPRINT_INVENTORY: "PRINTINVENTORY",
        MASSPRINT_ENVELOPE: "MASSPRINTENV",
        MASSPRINT_INVOICE: "MASSPRINTINV",
        MASSPRINT_INVENTORY: "MASSPRINTF107",
        MASSPRINT_REESTR: "PRINTREESTRMASS",
        RECOVERY_EMAIL_REQUEST: "PASSRECOVERY",
        RECOVERY_EMAIL_SUCCESS: "PASSRECOVERYSUCCESS",
        LICENCE_FINISHED: "FINISHED",
        TOO_MANY_ACCOUNTS: "TOOMANYACCOUNTS",
        SIGNIN: "SIGNIN",
        SIGNUP_LIVE: "SIGNUPLIVE",
        SIGNUP_AUTO: "SIGNUPAUTO",
        HASMANYACCOUNTS: "HASMANYACCOUNTS"
      }
    };
  },
  computed: {
    bugfix() {
      return this.$store.getters.BUGFIX;
    }
  },

  created() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      this.setGoal(mutation.type);
    });
    const bsObject = Bugsnag.start({
      apiKey: "143d30f5f62b8b27b0f6af7b55d9f8a6",
      plugins: [new BugsnagPluginVue()],
      onError: event => {
        if (this.bugfix == null) {
          this.$store.commit("SET_BUGFIX", Math.floor(Math.random() * 10000));
        }
        event.setUser(this.bugfix, null, null);
      }
    });
    const bugsnagVue = Bugsnag.getPlugin("vue");
    bugsnagVue.installVueErrorHandler(Vue);
    Vue.prototype.$bugsnag = bsObject;
  },
  methods: {
    setGoal(goal) {
      if (this.mutations.hasOwnProperty(goal)) {
        if (process.env.DEV) {
          console.log(goal);
        } else {
          ym(45857166, "params", {
            params: {
              bugfix: this.bugfix
            }
          });
          ym(45857166, "reachGoal", this.mutations[goal]);
        }
      }
    }
  },

  beforeDestroy() {
    this.unsubscribe();
  }
};
</script>
