<template>
  <q-card class="bg-primary text-white transall mwnone">
    <q-card-section v-if="state == 'empty'">
      <div class="text-h6 text-center">Создание аккаунта</div>
    </q-card-section>
    <q-card-section v-if="state == 'success'" class="q-px-lg">
      <div class="text-h6 text-left">
        Аккаунт создан. Получатель добавлен в базу.
      </div>
    </q-card-section>
    <q-card-section
      class="overflow-hidden bg-primary-light q-px-lg q-pb-lg"
      v-if="state == 'success'"
    >
      <div class="lpdata">
        Ваш логин:
        <span>{{ login }}</span>
      </div>
      <div class="q-pb-md lpdata">
        Ваш пароль:
        <span>{{ password }}</span>
      </div>
      <div
        class="q-pb-sm"
        @click="accepting = !accepting"
        style="line-height: 125%; overflow: hidden"
      >
        <a
          class="col text-white"
          href="https://777.xn--l1adcnfw.xn--p1ai/api/authcookie/"
          target="_blank"
          style="text-decoration: none"
          ><q-icon
            class="col-auto q-mr-sm q-mb-md float-left"
            :name="
              !(accepting || $q.cookies.has('cookieaccepted'))
                ? 'check_box_outline_blank'
                : 'check_box'
            "
            size="md"
          /><span style="display: inline-block" class="text-underline q-mt-xs"
            >Согласие с политикой конфиденциальности</span
          ></a
        >
      </div>
      <q-form class="full-width" @submit="checkEmail">
        <div class="row items-stretch">
          <q-input
            color="grey-3"
            bg-color="white"
            outlined
            stretch
            dense
            class="ssinput q-pr-md"
            v-model="email"
            type="email"
            prefix="Email:"
          >
            <template v-slot:prepend>
              <q-icon name="mail" />
            </template>
          </q-input>

          <q-btn
            stretch
            :disable="!(accepting || $q.cookies.has('cookieaccepted'))"
            unelevated
            no-caps
            color="secondary"
            class="ssbtnemail q-px-sm"
            label="Получить по почте"
            type="submit"
          />
        </div>
      </q-form>
    </q-card-section>
    <q-card-section
      class="bg-primary overflow-hidden q-px-lg"
      v-if="state == 'success'"
    >
      <q-btn
        flat
        no-caps
        class="ssbtngo"
        label="Продолжить работу"
        @click="closeAutoSignupWnd"
        :disable="!(accepting || $q.cookies.has('cookieaccepted'))"
      />
    </q-card-section>
    <q-linear-progress
      v-if="state == 'empty'"
      indeterminate
      size="md"
      color="accent"
    />
  </q-card>
</template>

<script>
export default {
  props: {
    dosignup: {
      //Запускает процесс создания аккаунта
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      autosignupdialog: false,
      email: "",
      emailbtnstate: false,
      accepting: false
    };
  },
  computed: {
    state() {
      return this.$store.getters.SIGNUPAUTO_STATE;
    },
    login() {
      return this.$store.getters.ACCOUNT.login;
    },
    password() {
      return this.$store.getters.ACCOUNT.password;
    }
  },
  watch: {
    dosignup: function(newVal) {
      if (newVal == true) {
        this.$store.dispatch("SIGNUPAUTO", {
          mode: "auto",
          browser: this.$q.platform.is.name,
          fid: this.$store.getters.FID
        });
      }
    }
  },
  methods: {
    closeAutoSignupWnd() {
      let l = "/";
      window.location = l;
    },
    checkEmail() {
      this.emailbtnstate = true;
      setTimeout(() => {
        this.emailbtnstate = false;
      }, 5000);
      this.$store.dispatch("CHANGE_USER_SETTINGS_FROM_AUTOSIGNUP", {
        login: this.login,
        password: this.password,
        email: this.email
      });
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === "SET_SIGNUPAUTO_STATE") {
        if (this.$store.getters.GET_SIGNUPAUTOSTATE == "success") {
          this.$emit("autosignup-success");
        } else if (this.$store.getters.GET_SIGNUPAUTOSTATE == "error") {
          this.$emit("autosignup-error");
        }
      } else if (mutation.type === "EMAIL_DONE") {
        let l = "/";
        window.location = l;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
</script>

<style lang="sass">
.eprogressouter
  min-height: 4px
.ssinput
  float: left
.ssbtnemail
  float: right
.ssbtngo
  float: right
.lpdata span
  font-size: 22px
  color: #FFF
  letter-spacing: 2px
.mwnone
  max-width: none
</style>
