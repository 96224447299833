<template>
  <q-dialog
    v-model="show"
    persistent
    transition-show="scale"
    transition-hide="scale"
  >
    <q-card class="mypdial text-white printpricedial">
      <q-card-section class="dialhead row items-center">
        <div class="text-h6">Сколько клеить марок на конверт</div>
        <q-space />
        <q-btn
          icon="close"
          unelevated
          color="accent"
          size="sm"
          round
          dense
          v-close-popup
          @click.stop="closePrice"
        />
      </q-card-section>
      <q-card-section class="q-pb-none">
        <div class="row items-center">
          <div class="col-auto q-mr-md ">Размер конверта:</div>
          <div class="col">
            <q-btn-toggle
              dense
              unelevated
              no-caps
              v-model="cufFormat"
              toggle-color="accent"
              color="secondary"
              padding=" xs sm"
              :options="formats"
            />
          </div>
        </div>
      </q-card-section>
      <q-card-section>
        <div class="row ">
          <div
            v-for="(item, index) in result[cufFormat]"
            :key="index"
            class="col-6 pricecard q-pa-md"
          >
            <div class="row items-center">
              <div class="col">
                <div v-for="(it, indx) in item" :key="indx">
                  <div class="row rwh">
                    <div class="col-8">
                      + {{ it.count }} {{ padeg[it.count] }}
                    </div>
                    <div class="col-4 text-right">{{ it.weight }} гр</div>
                  </div>
                </div>
              </div>
              <div class="col-5 q-pl-md text-h5 text-center bbppd">
                {{ index.split(".")[1] === "0" ? index.split(".")[0] : index }}
                руб
              </div>
            </div>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import Vue from "vue";
var groupBy = require("lodash/groupBy");

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    envelopeFormat: {
      type: String,
      default: "c5"
    }
  },
  data() {
    return {
      cufFormat: "c5",
      basePrice: 29,
      baseStep: 3.5,
      res: { c6: [], dl: [], c5: [], c4: [], b4: [] },
      result: {},
      formats: [
        { label: "C6", value: "c6" },
        { label: "DL", value: "dl" },
        { label: "C5", value: "c5" },
        { label: "С4", value: "c4" },
        { label: "B4", value: "b4" }
      ],
      padeg: {
        1: "лист",
        2: "листа",
        3: "листа",
        4: "листа",
        5: "листов",
        6: "листов",
        7: "листов",
        8: "листов",
        9: "листов",
        10: "листов",
        11: "листов",
        12: "листов",
        13: "листов",
        14: "листов",
        15: "листов",
        16: "листов"
      }
    };
  },
  computed: {
    f() {
      return this.$store.getters.CURFORMAT_OBJECT;
    }
  },

  watch: {
    show(val) {
      this.cufFormat = this.envelopeFormat;
    }
  },
  methods: {
    closePrice() {
      this.$emit("update:show", false);
    }
  },
  mounted() {
    for (let i = 0; i < 16; i++) {
      this.res["c6"].push({
        count: i + 1,
        weight: 4 + 5 * (1 + i),
        price:
          this.basePrice + this.baseStep * Math.floor((4 + 5 * (1 + i)) / 20)
      });
      this.res["dl"].push({
        count: i + 1,
        weight: 5 + 5 * (1 + i),
        price:
          this.basePrice + this.baseStep * Math.floor((5 + 5 * (1 + i)) / 20)
      });
      this.res["c5"].push({
        count: i + 1,
        weight: 7 + 5 * (1 + i),
        price:
          this.basePrice + this.baseStep * Math.floor((7 + 5 * (1 + i)) / 20)
      });
      this.res["c4"].push({
        count: i + 1,
        weight: 17 + 5 * (1 + i),
        price:
          this.basePrice + this.baseStep * Math.floor((17 + 5 * (1 + i)) / 20)
      });
      this.res["b4"].push({
        count: i + 1,
        weight: 22 + 5 * (1 + i),
        price:
          this.basePrice + this.baseStep * Math.floor((22 + 5 * (1 + i)) / 20)
      });
    }

    let aa = {};
    for (let key in this.res) {
      aa[key] = groupBy(this.res[key], function(item) {
        return item.price.toFixed(1);
      });
    }
    this.result = aa;
  }
};
</script>

<style lang="sass">
.printpricedial
  width: 550px
  height: 470px
  max-width: 550px!important
.rowprice:nth-child(even)
  background: rgba(0,0,0,0.15)
.rowprice
  font-size:18px

.pricecard
  border: 1px solid #366d78
.pricecard:nth-child(2)
  margin-left: -1px
.pricecard:nth-child(3)
  margin-top: -1px
.pricecard:nth-child(4)
  margin-left: -1px
  margin-top: -1px
.pricecard:nth-child(5)
  margin-top: -1px

.bbppd
  color: #fffb80

.rwh
  border-bottom: 1px dashed rgba(255,255,255, 0.4)
</style>
